import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut, SignIn, SignUp} from "@clerk/clerk-react";
import { useNavigate, Routes, Route } from "react-router-dom";
import Redirect from "./Redirect";
import ErrorPage from "./ErrorPage";
import "../styles/ClerkLayout.css";

/**
 * This is the primary Clerk provider components. 
 * This is required for the integration, it is responsible for
 * setting the publishable key and displaying the signin/signup 
 * components and routes.
 */ 

const ClerkLayout = ({pk, redirect}) => {
    const navigate = useNavigate();
    if (pk === "") return <div></div>;
    return redirect !== "" ? (            
        <ClerkProvider 
            navigate={navigate}
            publishableKey={pk} 
            appearance={{
                layout: {
                    socialButtonsPlacement: "bottom",
                    socialButtonsVariant: "blockButton"
            }}
        }> 
            <SignedIn>
                <Routes>
                    <Route path="*" element={ <Redirect redirect={redirect} /> } />
                </Routes>   
            </SignedIn>
            <SignedOut>
                <Routes>
                    <Route path="/sign-up" element={ <SignUp signInURL="/sign-in" redirectUrl={redirect} /> } />
                    <Route path="/sign-in" element={ <SignIn signUpURL="/sign-up" redirectUrl={redirect} /> } />
                    <Route path="/" element={ <RedirectToSignIn redirectUrl={redirect} /> }/>
                    <Route path="*" element={ <ErrorPage/> } />
                </Routes>
            </SignedOut>
        </ClerkProvider>
    ) : <Redirect />;
}

export default ClerkLayout;
