import heroBanner from "../assets/logo-hero-login.png";
import poweredBy from "../assets/logo_powerby_login.png";
import styles from "../styles/Header.module.css";

/**
 * This is the header element returned with required lou branding. 
 */

const Header = () => {
  return (
      <header className={styles.evHeaderContainer}>                
          <div className={styles.evHeroBanner}>
              <img src={heroBanner} alt="presentation"/>
          </div>
          <div className={styles.evSubBanner}>
              <span>Powered by</span>
              <img src={poweredBy} alt="presentation"/>
          </div>
      </header>
  );
}

export default Header;
