/**
 * If a page not found or other error occurs.
 * This is specific to lou login, not mendix application.
 */ 

const ErrorPage = () => {
    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>An unexpected error has occurred.</p>
        </div>
    );
}

export default ErrorPage;