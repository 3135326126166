import styles from "../styles/Footer.module.css";

/**
 * This is the footer element returned with required evosus links.
 * It requires a dynamically generated url for the Quote deeplink, tied
 * to the Mendix project.
 */

const Footer = ({quoteUrl}) =>{
  return (
    <footer className={styles.evFooterContainer}>
        <a className={styles.evFooterLink} href="https://www.evosus.com/lou-eula" rel="noopener noreferrer" target="_blank">EULA</a>
        <span className={styles.evFooterLinkSeparator}>/</span>
        <a className={styles.evFooterLink} href="https://www.evosus.com/sla" rel="noopener noreferrer" target="_blank">SLA</a>
        <span className={styles.evFooterLinkSeparator}>/</span>
        <a className={styles.evFooterLink} href="https://www.evosus.com/privacy" rel="noopener noreferrer" target="_blank">PRIVACY</a>
        <span className={styles.evFooterLinkSeparator}>/</span>
        <a className={styles.evFooterLink} href="https://www.evosus.com/lou-terms" rel="noopener noreferrer" target="_blank">TERMS</a>
        <span className={styles.evFooterLinkSeparator}>/</span>
        <a className={styles.evFooterLink} href={quoteUrl} rel="noopener noreferrer" target="_blank">QUOTE</a>
    </footer>
  );
}

export default Footer;
