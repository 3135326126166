import styles from "../styles/Redirect.module.css";
import { useEffect } from "react";

/**
 * This is the redirect spinner element returned when an authenticated user 
 * is returning with valide session. It is just to show during the redirection action occurs. 
 */

const Redirect = ({redirect}) => {
    
    useEffect(() => {
        if (redirect !== "" && typeof redirect !== 'undefined') {
            setTimeout(() => {
                return window.location.replace(redirect);
            }, 300)
        }
      }, [redirect]);
      
    // Return spinner
    return (
        <div>
            <div className={styles.evRedirectSpinner}/>
            <div className={styles.evRedirectMessage}>
                <p>Loading...</p>
            </div>   
        </div>
    );
}

export default Redirect;
